.opening-hours {
    display: flex;

    line-height: 20px;

    svg {
        width: 20px;
        height: 20px;

        margin-right: $padding-xsmall;
    }
}
