/* stylelint-disable no-descending-specificity */

h1, h2, h3, h4, h5, h6,
.heading--1, .heading--2, .heading--3, .heading--4 {
    margin-bottom: 1em;

    font-weight: $ui-font-regular;
    line-height: (4 / 3);

    &.title::first-letter {
        font-family: $font-accent;
        font-size: 1.5em;
        color: $ui-red;
    }

    &.is-center {
        text-align: center;
    }
}

h1,
.heading--1 {
    font-size: 3rem;

    @include media-breakpoint-up("md") {
        font-size: 4rem;
    }
}

h2,
.heading--2 {
    font-size: 2rem;

    @include media-breakpoint-up("md") {
        font-size: 2.4rem;
    }

    &.title:not(.is-center) {
        &::after {
            content: "";

            display: block;

            width: 30px;
            height: 2px;

            background-color: $ui-red;
        }
    }
}

h3,
.heading--3 {
    font-size: 1.8rem;

    @include media-breakpoint-up("md") {
        font-size: 2rem;
    }
}

h4,
.heading--4 {
    font-size: 1.6rem;

    @include media-breakpoint-up("md") {
        font-size: 1.8rem;
    }
}

h5,
.heading--5 {
    font-size: 1.6rem;
}
