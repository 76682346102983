.image-text {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up("sm") {
        flex-direction: row;
    }

    > * + * {
        margin-top: $padding-medium;

        @include media-breakpoint-up("sm") {
            margin-top: 0;
            margin-left: $padding-large;
        }
    }

    &__image {
        width: 100%;
        min-height: 400px;

        background-size: cover;
        background-position: center;

        @include media-breakpoint-up("sm") {
            width: 400px;
        }
    }
}
