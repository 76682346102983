$grid-breakpoints: (
    xs: 420,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
);

$site-width: 1320px;
$text-width: 720px;

$border-radius: 5px;