.language-switch {
    position: relative;

    &__fake-select {
        pointer-events: none;

        display: flex;
        align-items: center;

        padding: 2px 8px;

        border: 1px solid $ui-white;
        border-radius: 2px;

        transition: box-shadow 250ms ease-in-out;

        svg {
            margin-left: 3px;

            width: 12px;
            height: 12px;
        }
    }

    select {
        cursor: pointer;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: block;
        width: 100%;
        height: 100%;

        opacity: 0;

        &:hover,
        &:focus {
            + * {
                box-shadow: 0 0 0 3px rgba($ui-white, .25);
            }
        }
    }
}
