.gallery {
    &-item {
        margin-bottom: $padding-small;

        &__wrapper {
            cursor: zoom-in;

            &::after {
                content: "";

                display: block;

                width: 30px;
                height: 2px;

                margin: $padding-xsmall 0 0;

                background-color: $ui-red;

                transition: transform 250ms ease-in-out;
                transform-origin: bottom left;
            }

            &:hover {
                &::after {
                    transform: scaleX(2);
                }
            }
        }

        &__caption {
            padding-top: $padding-tiny;

            opacity: .8;
           
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}
