@mixin visually-hidden {
    position: absolute;
    left: -9999px;

    display: block;
    width: 1px;
    height: 1px;
}

.visually-hidden {
    @include visually-hidden;
}
