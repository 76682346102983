.tabs {
    position: sticky;
    top: 0;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: $padding-medium 0;

    border-top: 2px solid rgba($ui-black, .1);
    border-bottom: 2px solid rgba($ui-black, .1);

    background-color: $ui-white;

    font-size: 14px;
    font-weight: $ui-font-regular;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;

    @include media-breakpoint-up("sm") {
        font-size: 16px;
    }
}

.tab {
    &__link {
        display: block;

        padding: $padding-xsmall;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        margin-bottom: -2px;

        transition: border-color 250ms ease-in-out;

        &:hover,
        &:focus {
            border-bottom-color: $ui-red;
        }
    }
}
