.button {
    display: inline-flex;

    padding: 11px 20px;
    border-radius: 2px;

    background-color: $ui-red;

    font-size: 15px;
    font-weight: $ui-font-bold;
    text-transform: uppercase;
    letter-spacing: .5px;

    transition: box-shadow 250ms ease-in-out;

    &:hover,
    &:focus {
        box-shadow: 0 0 0 3px rgba($ui-red, .25);
    }
}
