.menus {
    max-width: $text-width;
    margin: auto;
}

.menu {
    $root: &;

    &__title {
        margin: $padding-small 0;

        text-align: center;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        width: 100%;

        margin: $padding-small 0;
    }

    &__dish {
        &::first-letter {
            font-family: $font-accent;
            font-size: 1.5em;
            color: $ui-red;
            font-weight: $ui-font-regular;
        }
    }

    &__price {
        margin-left: $padding-medium;

        opacity: .8;
        font-weight: $ui-font-regular;
        line-height: 2;
        white-space: nowrap;
    }

    &.is-center {
        text-align: center;

        > * + * {
            &::before {
                content: "---";

                display: block;

                margin: $padding-small 0;

                opacity: .4;
            }
        }

        #{$root}__item {
            display: block;

            margin: 0;
        }
    }
}
