.text {
    max-width: $text-width;
    margin-left: auto;
    margin-right: auto;

    > * + * {
        margin-top: 1em;
    }

    a {
        display: inline;
        line-height: 1;

        background-size: 1px 1em;
        box-shadow: inset 0 -3px rgba($ui-black, .1);

        transition: box-shadow 250ms ease-in-out;

        &:hover,
        &:focus {
            box-shadow: inset 0 -3px $ui-red;
        }
    }
}
