.container {
    width: calc(100% - #{$padding-xsmall * 2});
    max-width: $site-width;

    padding: $padding-small;
    margin: 0 $padding-xsmall;

    @include media-breakpoint-up("sm") {
        width: 100%;

        padding: $padding-large;
        margin: auto;
    }
}
