/* stylelint-disable value-keyword-case */

/* latin-ext */
@font-face {
    font-family: "Great Vibes";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Great Vibes"), local("GreatVibes-Regular"), url(https://fonts.gstatic.com/s/greatvibes/v7/RWmMoKWR9v4ksMfaWd_JN9XLiaQoDmlrMlY.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Great Vibes";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Great Vibes"), local("GreatVibes-Regular"), url(https://fonts.gstatic.com/s/greatvibes/v7/RWmMoKWR9v4ksMfaWd_JN9XFiaQoDmlr.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import url('https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:300,400&display=swap');

$ui-font-light: 300;
$ui-font-regular: 400;
$ui-font-medium: 500;
$ui-font-bold: 700;

$font-base: "Fira Sans Condensed", -apple-system, blinkmacsystemfont, "Segoe UI", arial, helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-accent: "Great Vibes", $font-base, serif;

$font-size-base: 1.8rem;
$font-size-small: 1.6rem;
$font-size-xsmall: 1.4rem;
