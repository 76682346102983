$ui-black: #1a1a1a;
$ui-grey: #666666;
$ui-lightgrey: #f2f2f2;
$ui-white: #ffffff;

$ui-red: #ec1d27;
$ui-green: #4fbb3d;

$text-color: $ui-white;
$text-color-muted: $ui-grey;

$border-color: #e6e6e6;
$bg-color: #f7f7f7;
