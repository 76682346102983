@import "./text";
@import "./image-text";

.content-blocks {
    position: relative;
    z-index: 1;

    border-radius: $border-radius;

    background-color: $ui-white;

    color: $ui-black;

    h2 {
        max-width: $text-width;
        margin-left: auto;
        margin-right: auto;
    }
}

.content-block {
    position: relative;

    + .content-block {
        margin-top: $padding-large;
        padding-top: $padding-large;

        &::before {
            content: "---";

            display: block;

            position: absolute;
            top: 0;
            left: 50%;

            transform: translate(-50%, -50%);

            opacity: .4;
        }
    }
}

.hero__container + .content-blocks {
    margin-top: -$padding-xsmall;

    @include media-breakpoint-up("sm") {
        margin-top: -$padding-xlarge;
    }
}
