.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    max-width: $site-width;
    padding: $padding-xsmall $padding-large;
    margin: 0 auto;

    @include media-breakpoint-up("sm") {
        flex-direction: row;

        padding: $padding-large;
    }

    > * {
        flex: 1 0 auto;
        width: 100%;

        margin: $padding-small 0;

        @include media-breakpoint-up("md") {
            flex: 0 0 auto;
            width: 50%;

            margin: $padding-small 0;
        }

        @include media-breakpoint-up("lg") {
            width: 25%;
        }
    }

    &__list {
        > * + * {
            margin-top: $padding-small;
        }

        @include media-breakpoint-down("xs") {
            display: flex;
            flex-wrap: wrap;

            > * + * {
                margin-top: 0;

                &::before {
                    content: "\00a0 • \00a0";
                }
            }
        }
    }

    &__item {
        display: flex;
    }

    a {
        box-shadow: inset 0 -3px rgba($ui-white, .2) !important;

        &:hover,
        &focus {
            box-shadow: inset 0 -3px $ui-red !important;
        }
    }
}
