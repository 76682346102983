html {
    scroll-behavior: smooth;

    font-size: (100% / 1.6); // 1rem = 10px
}

body {
    width: 100%;
    max-width: 100vw;

    overflow-x: hidden;

    background-color: $ui-black;

    color: $text-color;
    font-family: $font-base;
    font-size: $font-size-base;
    font-weight: $ui-font-light;
    line-height: 1.5;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.no-scroll {
        overflow-y: hidden;
    }
}

button {
    font: inherit;

    background-color: transparent;

    border: 0;
    border-radius: 0;

    cursor: pointer;
}

a[role="button"] {
    cursor: pointer;
}

iframe {
    width: 100%;

    border: none;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

svg {
    display: block;
    width: 100%;
    height: auto;

    fill: currentColor;
}
