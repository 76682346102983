.contact {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up("sm") {
        flex-direction: row;
    }

    &__info {
        > * + * {
            margin-top: $padding-small;
        }
    }

    &__map {
        margin-top: $padding-medium;

        @include media-breakpoint-up("sm") {
            flex: 0 0 auto;

            width: 500px;

            margin-top: 0;
            margin-left: $padding-medium;
        }
    }
}

.contact-info {
    &__item {
        display: flex;

        line-height: 20px;

        + * {
            margin-top: $padding-small;
        }

        svg {
            width: 20px;
            height: 20px;

            margin-right: $padding-xsmall;
        }
    }
}
