.header {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 100px;

    padding: 0 $padding-large;

    font-size: 1.6rem;
    font-weight: $ui-font-regular;

    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 2px $ui-black;

    z-index: 99;

    @include media-breakpoint-down("sm") {
        height: 80px;
        padding: 0 $padding-small;
    }

    h1 {
        margin: 0;
    }

    &__logo {
        flex: 0 0 auto;
        display: flex;

        font-size: 1.6rem;

        svg {
            margin-right: $padding-xsmall;
            width: 40px;
        }
    }

    &__nav {
        margin-left: auto;
        margin-right: $padding-small;

        @include media-breakpoint-down("sm") {
            transform: translate3d(100%, 0, 0) scaleX(0);
            transition: all 450ms ease-in-out;

            z-index: 99;

            position: fixed;
            top: 0;
            left: 0;

            display: flex;

            width: 100vw;
            height: 100vh;

            background-color: $ui-black;

            &.is-open {
                transform: translate3d(0, 0, 0) scaleX(1);
            }
        }
    }

    &__list {
        display: flex;
        align-items: center;

        > * {
            + * {
                margin-left: $padding-small;
            }

            @include media-breakpoint-down("sm") {
                margin: $padding-small;
            }
        }

        @include media-breakpoint-down("sm") {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;

            text-align: center;
        }
    }

    &__link {
        &.is-active {
            &:before {
                background-color: $ui-white;
                transform: scaleX(1);
            }
    
            &:after {
                background-color: $ui-white;
                transform: scaleX(1);
            }
        }
    }

    &__mobile-toggle {
        display: none;

        z-index: 100;

        color: $ui-white;

        svg {
            width: 24px;
            height: 22px;
        }

        @include media-breakpoint-down("sm") {
            display: block;
        }
    }

    a {
        position: relative;

        &:before {
            content: "";

            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 2px;

            border-radius: 10px;
            background-color: rgba($ui-white, .25);

            transform: scaleX(0);
            transform-origin: top left;
    
            transition: transform 250ms;
        }
    
        &:after {
            content: "";

            position: absolute;
            bottom: 0;
            right: 0;

            width : 100%;
            height: 2px;

            border-radius: 10px;
            background-color: rgba($ui-white, .25);

            transform: scaleX(0);
            transform-origin: bottom right;
    
            transition: transform 250ms;
        }
    
        &:hover {
            &:before {
                transform: scaleX(1);
            }
    
            &:after {
                transform: scaleX(1);
            }
        }
    }
}
