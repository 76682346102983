@import "./flickity";

.hero {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 80px;

    width: 100vw;
    min-height: 90vh;

    background-image: url('./bg.jpg');
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up("sm") {
        min-height: 80vh;

        padding-top: 0;
    }

    &__container {
        z-index: 0;
        margin-top: -100px;

        @include media-breakpoint-down("sm") {
            margin-top: -80px;
        }
    }

    &__logo {
        padding: $padding-small;
        border-radius: $border-radius;

        width: 200px;

        background-color: $ui-white;

        @include media-breakpoint-up("sm") {
            width: 300px;
        }
    }

    &__info {
        max-width: $text-width;
        padding: $padding-xsmall;
        
        text-align: center;
        text-shadow: 0 0 5px rgba($ui-black, .5);

        transform: translateY(-20px);

        @include media-breakpoint-up("sm") {
            padding: $padding-medium;

            transform: none;
        }

        .title {
            font-size: 32px;

            @include media-breakpoint-up("sm") {
                font-size: 48px;
            }
        }

        p {
            font-weight: $ui-font-bold;
        }

        > * + * {
            margin-top: $padding-small;
        }
    }
}
