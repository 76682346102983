*,
*::before,
*::after {
    margin: 0;
    padding: 0;

    box-sizing: border-box;
}

a {
    color: currentColor;
    text-decoration: none;
}

ul,
ol {
    list-style-type: none;
}
