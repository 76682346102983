.flickity {
    &-prev-next-button {
        border: 1px solid $ui-white !important;
        background-color: transparent !important;

        transition: box-shadow 250ms ease-in-out;

        @include media-breakpoint-down("sm") {
            display: none;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 0 3px rgba($ui-white, .25);
        }
    }

    &-button-icon {
        fill: $ui-white !important;
    }

    &-page-dots {
        bottom: 20px !important;

        .dot {
            width: 40px !important;
            height: 4px !important;

            margin: 0 5px !important;
            border-radius: 4px !important;

            background-color: $ui-white !important;
        }

        @include media-breakpoint-up("sm") {
            bottom: 100px !important;
        }
    }
}
